import React, { useContext } from "react";
import NavigationItem from "../NavigationItem/NavigationItem";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  AcademicCapIcon,
  TrophyIcon,
  ClockIcon,
  UserCircleIcon,
  PlayIcon,
  ListBulletIcon,
  BuildingOfficeIcon,
  PencilSquareIcon
  

} from "@heroicons/react/24/outline";

import { UserLevelContext } from "../../context/UserLevelContext";
import { CompanyTypeContext } from "../../context/CompanyTypeContext";


export default function Navigation({
  userLoggedIn,
  selectedIndex,
  setSelectedIndex,
  vesselId,
  companies,
  handlChangeCompany,
  company
}) {
  const navigate = useNavigate();
  const userLevel = useContext(UserLevelContext);
  const companyType = useContext(CompanyTypeContext);

  const clickedNavigationItem = (href, index) => {
    setSelectedIndex(index);
    navigate(href);
  };

  return (
    <>
      {userLoggedIn && (
        <>
          <NavigationItem
            name="Home"
            Icon={HomeIcon}
            onClicked={() => clickedNavigationItem("/home", 0)}
            isCurrent={selectedIndex === 0}
          />

          {userLevel == 4 && (
            <>
              <h3
                className="pb-2 px-3 pt-5 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                id="projects-headline"
              >
                Select Company
              </h3>
              <select
                id="company"
                name="company"
                className="mt-5 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                onChange={(e) => {
                  const selectedId = e.target.value; 
                  handlChangeCompany(selectedId);
                }}
                value={company?.id}
              >
                {companies &&
                  companies.map((comp) => (
                    <option
                      key={comp.id}
                      value={comp.id}
                    >
                      {comp.CompanyName}
                    </option>
                  ))}
              </select>
            </>  
          )}

          {(userLevel === 1 || userLoggedIn.showTraining) && (
            <>
              <h3
                className="px-3 pt-5 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                id="projects-headline"
              >
                Training Course
              </h3>

              <NavigationItem
                name="My Course"
                Icon={AcademicCapIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/course", 1)}
                isCurrent={selectedIndex === 1}
              />
              <NavigationItem
                name="My History"
                Icon={ClockIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/history", 2)}
                isCurrent={selectedIndex === 2}
              />
              <NavigationItem
                name="My Certifications"
                Icon={TrophyIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/certifications", 3)}
                isCurrent={selectedIndex === 3}
              />

            </>
          )}

          {(userLevel === 1 || userLoggedIn.showTraining || userLoggedIn.showMyVideos) && (

            <NavigationItem
              name="My Videos"
              Icon={PlayIcon}
              setSelectedIndex={setSelectedIndex}
              onClicked={() => clickedNavigationItem("/video-library", 4)}
              isCurrent={selectedIndex === 4}
            />
          )}

          {userLevel >= 2 && (
            <>
              <h3
                className="px-3 pt-5 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                id="projects-headline"
              >
                Management
              </h3>
              <NavigationItem
                name={`My Users`}
                Icon={UserCircleIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/users", 5)}
                isCurrent={selectedIndex === 5}
              />

              {userLevel >= 3 && companyType === "trainingAndManagement" && (
                <NavigationItem
                  name="My Fleet"
                  Icon={ListBulletIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem("/fleet", 6)}
                  isCurrent={selectedIndex === 6}
                />
              )}

              {userLevel == 2 && companyType === "trainingAndManagement" && (
                <NavigationItem
                  name="My Vessel"
                  Icon={ListBulletIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem(`/vessel/${vesselId}`, 7)}
                  isCurrent={selectedIndex === 7}
                />
              )}

              {userLevel == 4 && (
                <>
                <NavigationItem
                  name="Create Company"
                  Icon={BuildingOfficeIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem(`/add-company`, 8)}
                  isCurrent={selectedIndex === 8}
                />
                <NavigationItem
                  name="Edit Company"
                  Icon={PencilSquareIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem(`/edit-company`, 9)}
                  isCurrent={selectedIndex === 9}
                />
                </>
              )}
              
            </>
          )}
        </>
      )}
    </>
  );
}
