import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import PageHeader from "../../components/PageHeader/PageHeader";
import getBoardingTypes from "../../data/queries/getAllBoardingTypes";
import getJobRoles from "../../data/queries/getAllJobRoles";
import getRegions from "../../data/queries/getRegions";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function Company({ displaySomeToast, companyData, setCompanyId, setSelectedIndex, setCompanyChangeFlag }) {
 
  const [company, setCompany] = useState({
    CompanyName: "",
    user_roles: [],
    user_boarding_types: [],
    companyType: "",
    userRenewalReminderIntervalDays: "",
    userTrainingReminderIntervalDays: "",
    UsersToCompleteCourseInAllocatedDate: false,
    regions: [],
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [jobRoles, setJobRoles] = useState([]);
  const [boardingTypes, setBoardingTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(companyData)
    setCompany(companyData);
  }, [companyData]);

  useEffect(() => {
    if(company.id){
      setCompanyId(company.id); 
      setCompanyChangeFlag(true);
      queryClient.invalidateQueries(["company"]);  
    }
  }, [company.id]);  

  useEffect(() => {
    async function fetchData() {
      try {
        const jobRolesResponse = await getJobRoles();
        const boardingTypesResponse = await getBoardingTypes();
        const region = await getRegions(); 
        setJobRoles(jobRolesResponse.data.data); 
        setBoardingTypes(boardingTypesResponse.data.data); 
        setRegions(region.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCompany((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file);
  };

  const validateForm = () => {
    let errors = {};
    if (!company.CompanyName.trim()) errors.CompanyName = "Company Name is required";
    if (company.user_roles.length === 0) errors.user_roles = "At least one User Role is required";
    if (company.user_boarding_types.length === 0) errors.user_boarding_types = "At least one Boarding Type is required";
    if (!company.companyType) errors.companyType = "Company Type is required";
    if(!company.id){
      if (!company.firstName.trim()) errors.firstName = "First Name is required";
      if (!company.lastName.trim()) errors.lastName = "Last Name is required";
      if (!company.username.trim()) errors.username = "Username is required";
      if (!company.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) errors.email = "Valid Email is required";
      if (!company.password.trim()) errors.password = "Password is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const createCompany = () => {
    if (!validateForm()) {
      displaySomeToast("Please correct the errors before submitting.", -1);
      return;
    }
    createCompanyMutation.mutate(company);
  };

  const createCompanyMutation = useMutation(
    () => {
      let token = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).jwt;
      const formData = new FormData();
      Object.keys(company).forEach((key) => {
        if (Array.isArray(company[key])) {
          company[key].forEach((item, index) => {
            formData.append(key, item.value);
          });
        } else {
          if(key=="UsersToCompleteCourseInAllocatedDate"){
            formData.append(key, company[key] ?? false);
          }else{
            formData.append(key, company[key]);
          }
          
        }
      });
      
      if (photoFile) {
        formData.append(`files.photo`, photoFile, photoFile.name);
        }

      return axios.post(`${process.env.REACT_APP_API_ROOT}/company/create-company`, formData, {
        headers: {
          Accept: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["companies"]);
       
       
        if(company.id){
          displaySomeToast("Company updated successfully");
          queryClient.invalidateQueries(["company"]);
        }else{
          displaySomeToast("Company created successfully");
          setCompanyId(res.data.company.id); 
          queryClient.invalidateQueries(["company"]);  
          navigate('/');
          setSelectedIndex(0)
          setCompany({
            CompanyName: "",
            user_roles: [],
            user_boarding_types: [],
            companyType: "",
            userRenewalReminderIntervalDays: "",
            userTrainingReminderIntervalDays: "",
            UsersToCompleteCourseInAllocatedDate: false,
            regions: [],
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
          });
        }
      },
      onError: (error) => {
          console.error("Error creating company:", error);
          const errorMessage = error?.response?.data?.error?.message || "An unexpected error occurred. Please try again.";
          displaySomeToast(errorMessage, -1);
      },
    }
  );

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title={company.id ? "Update Company":"Create Company"} />
        <div className="mt-4">
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <h1 className="font-bold text-lg">Company Details</h1>
                </div>

                {/* Company Name */}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="CompanyName" className="block text-sm font-medium text-gray-700">
                    Company Name <span class="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="CompanyName"
                    id="CompanyName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    value={company.CompanyName}
                    onChange={handleChange}
                  />
                  {errors.CompanyName && <p className="text-red-500 text-sm">{errors.CompanyName}</p>}
                </div>

                <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="photo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {company.id ? "Replace Logo" : "Logo"}
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="photo"
                        accept="image/*"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        onChange={handlePhotoChange}
                      />
                    </div>

                {/* User Role Dropdown */}
                <div className="col-span-6 sm:col-span-3 react-select">
                  <label htmlFor="user_roles" className="block text-sm font-medium text-gray-700">
                    User Role <span class="text-red-500">*</span>
                  </label>
                  <Select
                    id="user_roles"
                    isMulti
                    options={jobRoles.map((role) => ({
                      value: role.id,
                      label: role.attributes.RoleName,
                    }))}
                    value={company.user_roles}
                    onChange={(selectedOptions) => {
                      setCompany({ ...company, user_roles: selectedOptions });
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                  />
                  {errors.user_roles && <p className="text-red-500 text-sm">{errors.user_roles}</p>}
                </div>

                {/* User Boarding Type Dropdown */}
                <div className="col-span-6 sm:col-span-3 react-select">
                  <label htmlFor="user_boarding_types" className="block text-sm font-medium text-gray-700">
                    Boarding Type <span class="text-red-500">*</span>
                  </label>
                  <Select
                    id="user_boarding_types"
                    isMulti
                    options={boardingTypes.map((type) => ({
                      value: type.id,
                      label: type.attributes.BoardingTypeName,
                    }))}
                    value={company.user_boarding_types}
                    onChange={(selectedOptions) => {
                      setCompany({ ...company, user_boarding_types: selectedOptions });
                    }}
                    className="mt-1 block w-full rounded-md shadow-sm sm:text-sm"
                  />
                  {errors.user_boarding_types && <p className="text-red-500 text-sm text-red-500">{errors.user_boarding_types}</p>}
                </div>

                {/* Other Fields */}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="companyType" className="block text-sm font-medium text-gray-700">
                    Company Type <span class="text-red-500">*</span>
                  </label>
                  <select
                    name="companyType"
                    id="companyType"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    value={company.companyType}
                    onChange={handleChange}
                  >
                    <option value="">Select Company Type</option>
                    <option value="training">Training</option>
                    <option value="trainingAndManagement">Training and Management</option>
                  </select>
                  {errors.companyType && <p className="text-red-500 text-sm">{errors.companyType}</p>}
                </div>

                {/* User Training Reminder Interval Days */}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="userTrainingReminderIntervalDays" className="block text-sm font-medium text-gray-700">
                    User Training Reminder Interval (Days)
                  </label>
                  <input
                    type="number"
                    name="userTrainingReminderIntervalDays"
                    id="userTrainingReminderIntervalDays"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    value={company.userTrainingReminderIntervalDays??""}
                    onChange={handleChange}
                  />
                </div>

                {/* User Renewal Reminder Interval Days */}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="userRenewalReminderIntervalDays" className="block text-sm font-medium text-gray-700">
                    User Renewal Reminder Interval (Days)
                  </label>
                  <input
                    type="number"
                    name="userRenewalReminderIntervalDays"
                    id="userRenewalReminderIntervalDays"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    value={company.userRenewalReminderIntervalDays??""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 react-select">
                  <label htmlFor="regions" className="block text-sm font-medium text-gray-700">
                    Region
                  </label>
                  <Select
                    id="regions"
                    isMulti
                    options={regions.map((region) => ({
                      value: region.id,
                      label: region.attributes.Title,
                    }))}
                    value={company.regions}
                    onChange={(selectedOptions) => {
                      setCompany({ ...company, regions: selectedOptions });
                    }}
                    className="mt-1 block w-full rounded-md shadow-sm sm:text-sm"
                  />
                  {errors.regions && <p className="text-red-500 text-sm">{errors.regions}</p>}
                </div>

                {/* Checkbox */}
                <div className="col-span-6 sm:col-span-3 flex items-center">
                  <input
                    type="checkbox"
                    name="UsersToCompleteCourseInAllocatedDate"
                    checked={company.UsersToCompleteCourseInAllocatedDate}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">
                    Complete course in allocated date
                  </label>
                </div>
                 {!company.id &&
                 <>
                  <div className="col-span-6">
                    <h1 className="font-bold text-lg">Company Manager Details</h1>
                  </div>

                  {/* First Name */}
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                      First Name <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                      value={company.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                      Last Name <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                      value={company.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                      Username <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                      value={company.username}
                      onChange={handleChange}
                    />
                    {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                      value={company.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Password <span class="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                      value={company.password}
                      onChange={handleChange}
                    />
                    {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                  </div>
                </>
                }   
                {/* Submit Button */}
              </div>
            </div>
            <div className="flex justify-between bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                onClick={createCompany}
                className="inline-flex justify-center rounded-md border border-transparent bg-fathom-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-fathom-blue focus:ring-offset-2"
              >
                {company.id ? "Update Company":"Create Company"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
