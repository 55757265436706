import axios from "axios";

export default function getBoardingTypes() {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  if (token) {
    return axios.get(
      `${process.env.REACT_APP_API_ROOT}/user-boarding-types`,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
