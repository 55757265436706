import React, { useState, useMemo,useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { downloadCSV } from "../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { UserLevelContext } from "../../context/UserLevelContext";
const UsersTable = ({
  data,
  showUserEdit,
  showDisableUser,
  showDeleteInvite,
  showUserStatus,
  selectedTab,
  companyType,
  setCertificate
}) => {
  const columns = useMemo(() => {
    const baseColumns = [];

    if (companyType === "trainingAndManagement") {
      if (selectedTab !== 1) {
        baseColumns.push({
          label: "rank",
          name: "Rank",
          selector: (row) => row.rank?.name,
          sortable: true,
          sortFunction: (a, b) => {
            const sortOrderA = a.rank?.sortOrder || 0;
            const sortOrderB = b.rank?.sortOrder || 0;
            return sortOrderA - sortOrderB;
          },
        });
      }
    }

    if (companyType === "trainingAndManagement") {
      baseColumns.push({
        label: "vessel",
        name: "Vessel",
        selector: (row) => row.vessel?.name || "No Vessel",
        cell: (row) =>
          row.vessel ? (
            <Link to={`/vessel/${row.vessel?.id}`}>{row.vessel?.name}</Link>
          ) : (
            "No Vessel"
          ),
        sortable: true,
      });
    }

    baseColumns.push({
      label: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    });

    if (selectedTab === 0 || selectedTab === 2) {
      baseColumns.push(
        {
          label: "firstName",
          name: "First Name",
          selector: (row) => row.firstName,
          sortable: true,
        },
        {
          label: "lastName",
          name: "Last Name",
          selector: (row) => row.lastName,
          sortable: true,
        },
        {
          label: "expiry",
          name: "Expiry",
          selector: (row) => row.expiry,
          sortable: true,
          cell: (row) => {
            const expiryDate = new Date(row.expiry);
            const formattedExpiry = isValidDate(expiryDate)
              ? expiryDate.toLocaleDateString("en-GB")
              : "NA";
            const color = isValidDate(expiryDate)
              ? getColorBasedOnExpiry(expiryDate)
              : "black"; // Assuming default color is black
            const cellStyle = {
              color: color,
            };
            return <div style={cellStyle}>{formattedExpiry}</div>;
          },
        },

        {
          label: "progressPct",
          name: "Progress",
          cell: (row) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => showUserStatus(row)}
              >
                <div
                  style={{
                    width: `${row.progressPct}%`,
                    backgroundColor: getColorBasedOnPercentage(row.progressPct)
                      .backgroundColor,
                    height: "20px",
                    borderRadius: "3px",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: getColorBasedOnPercentage(row.progressPct).fontColor,
                  }}
                >{`${row.progressPct}%`}</span>
              </div>
            </>
          ),
          sortable: true,
          sortFunction: (rowA, rowB, direction) => {
            const progressA = rowA.progressPct;
            const progressB = rowB.progressPct;

            if (progressA < progressB) {
              return direction === "asc" ? -1 : 1;
            }
            if (progressA > progressB) {
              return direction === "asc" ? 1 : -1;
            }
            return 0;
          },
        }
      );
    }

    baseColumns.push({
      label: "actions",
      name: "Actions",
      cell: (row) => (
        <>
          {row.accepted && (
            <div className="flex flex-col gap-1 my-1">
              <div>
                <span
                  onClick={() => showUserEdit(row)}
                  className="text-fathom-blue cursor-pointer"
                  >
                  Edit
                </span>
                {selectedTab === 0 && userLevel == 4 &&  (
                  <span
                  onClick={() => showDisableUser(row.id)}
                  className="text-fathom-red cursor-pointer pl-1"
                  >
                    Disable
                  </span>
                )}
              </div>
              {getColorBasedOnExpiry(new Date(row.expiry)) !== "green" && isValidDate(new Date(row.expiry)) && (
                <span
                  onClick={() => setCertificate(row.certificateId)}
                  className="text-fathom-blue cursor-pointer"
                  style={{wordSpacing: "-1px"}}
                >
                  Send reminder email
                </span>
              )}
            </div>
          )}
          {!row.accepted && (
            <span
              onClick={() => showDeleteInvite(row.id)}
              className="text-fathom-red cursor-pointer"
            >
              Delete Invite
            </span>
          )}
        </>
      ),
    });

    return baseColumns;
  }, [
    companyType,
    selectedTab,
    showDeleteInvite,
    showDisableUser,
    showUserEdit,
    showUserStatus,
    setCertificate,
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const userLevel = useContext(UserLevelContext);
  const filteredData = useMemo(() => {
    let result = data;
    if (selectedRegion) {
      result = result.filter((item) => item.region?.id == selectedRegion);
    }

    if (searchTerm) {
      result = result.filter((item) => {
        return Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }

    return result;
  }, [data, searchTerm, selectedRegion]);

  const Export = ({ onExport, filename }) => (
    <button
      type="button"
      className="inline-flex items-center mt-2 px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
      onClick={() => onExport(filename)}
    >
      <FontAwesomeIcon icon={faFileCsv} className="h-4 w-4 pr-2 text-white" />
      Export
    </button>
  );

  const actionsMemo = React.useMemo(() => {
    const fileName = "users_export.csv";
    return (
      <Export
        filename={fileName}
        onExport={() =>
          downloadCSV(
            filteredData,
            [
              "email",
              "firstName",
              "lastName",
              "expiry",
              "progressPct",
              "rank",
              "vessel",
            ],
            fileName
          )
        }
      />
    );
  }, [filteredData]);

  const regions = React.useMemo(() => {
    const regionMap = new Map();
    if(data){
      data.forEach(item => {
        if (item.region && item.region.id) {
          regionMap.set(item.region.id, item.region);
        }
      });
    }
    return Array.from(regionMap.values());
  }, [data]);


  const getColorBasedOnPercentage = (percentage) => {
    let backgroundColor, fontColor;

    if (percentage >= 80) {
      backgroundColor = "green";
      fontColor = "#fff";
    } else if (percentage >= 50) {
      backgroundColor = "#ffbf00";
      fontColor = "#000";
    } else {
      backgroundColor = "#d80754"; // Fathom Red
      fontColor = "#000";
    }

    return { backgroundColor, fontColor };
  };

  const getColorBasedOnExpiry = (expiryDate) => {
    const currentDate = new Date();
    const differenceInMilliseconds = expiryDate - currentDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    if (differenceInDays > 30) {
      return "green"; // More than a month to go
    } else if (differenceInDays > 0) {
      return "orange"; // Less than a month to go
    } else {
      return "#d80754"; // Expired, Fathom Red
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-end gap-4 mb-3">
        {regions && regions.length > 0 && userLevel == 3 &&
        <select
          className="form-control shadow-sm focus:ring-fathom-blue focus:border-fathom-blue block sm:text-sm border-gray-300 rounded-md"
          value={selectedRegion}
          onChange={(e) => setSelectedRegion(e.target.value)}
        >
          <option value="">All Regions</option>
          {regions.map((region) => (
            <option key={region.id} value={region.id}>
              {region.Title}
            </option>
          ))}
        </select>
        }
        <input
          type="text"
          className="form-control shadow-sm focus:ring-fathom-blue focus:border-fathom-blue block sm:text-sm border-gray-300 rounded-md"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        striped
        hover
        condensed
        actions={actionsMemo}
      />
    </div>
  );
};

export default UsersTable;
