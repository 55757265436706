import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";

export default function UserEditModal({
  showModal,
  setShowModal,
  editUser,
  ranks,
  vessels,
  companyType,
}) {
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [firstName, setFirstName] = useState(editUser?.firstName);
  const [lastName, setLastName] = useState(editUser?.lastName);
  const [email, setEmail] = useState(editUser?.email);
  const [rank, setRank] = useState(editUser?.rank?.id);
  const [vessel, setVessel] = useState(editUser?.vessel?.id);

  useEffect(() => {
    if (editUser && editUser.firstName) setFirstName(editUser.firstName);
    if (editUser && editUser.lastName) setLastName(editUser.lastName);
    if (editUser && editUser.email) setEmail(editUser.email);
    if (editUser && editUser.rank) setRank(editUser?.rank?.id);
    if (editUser && editUser.vessel) setVessel(editUser?.vessel?.id);
  }, [editUser]);

  // Mutations
  const editUserMutation = useMutation(
    () => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/edituser`,
        {
          // userId, email,vesselId, firstName, lastName, rankId
          userId: editUser.createdUserId,
          email: email,
          vesselId: vessel,
          firstName: firstName,
          lastName: lastName,
          rankId: rank,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
        setEmail("");
      },
    }
  );

  const doEditUser = () => {
    setErrorList([]);
    let errors = [];

    if(companyType === 'trainingAndManagement') {
    if (rank === "" || rank === null || rank === undefined) {
      errors.push("Rank is required");
    }
  }

    if (firstName === "" || firstName === null || firstName === undefined) {
      errors.push("First Name is required");
    }

    if (lastName === "" || lastName === null || lastName === undefined) {
      errors.push("Last Name is required");
    }

    if (email === "" || email === null || email === undefined) {
      errors.push("Email is required");
    }

    if (companyType === "trainingAndManagement" && (vessel === "" || vessel === null || vessel === undefined)) {
      errors.push("Vessel is required");
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      editUserMutation.mutate({
        id: editUser.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        rank: rank,
        vessel: vessel,
      });
      setShowModal(false);
    }
  };

  return (
    <div>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setShowModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                    <PencilSquareIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Edit User
                    </Dialog.Title>
                  </div>
                </div>
                {companyType === "trainingAndManagement" && (
                  <div className="mt-5">
                    <label
                      htmlFor="rank"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Rank
                    </label>
                    <select
                      id="rank"
                      name="rank"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      onChange={(e) => setRank(e.target.value)}
                      value={rank}
                    >
                      <option value={0}>Please select rank</option>
                      {ranks &&
                        ranks.map((rank) => (
                          <option
                            key={rank.id}
                            value={rank.id}
                          >
                            {rank.attributes.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                <div className="mt-5">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {companyType === "trainingAndManagement" && (
                  <div className="mt-5">
                    <label
                      htmlFor="vessel"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Vessel
                    </label>
                    <select
                      id="vessel"
                      name="vessel"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      onChange={(e) => setVessel(e.target.value)}
                      value={vessel}
                    >
                      <option key={-1} value="-1">
                        No Vessel
                      </option>
                      {vessels &&
                        vessels.map((vessel) => (
                          <option
                            key={vessel.id}
                            value={vessel.id}
                          >
                            {vessel.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                <div className="mt-5 flex justify-end">
                  <button
                    type="button"
                    className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fathom-blue hover:bg-fathom-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue-dark"
                    onClick={doEditUser}
                  >
                    Update
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
          <ErrorDialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
            title="Error"
            onConfirm={() => setErrorDialogOpen(false)}
          >
            {errorMessage}
          </ErrorDialog>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
