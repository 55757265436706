import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../services/utils";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import HandThumbUpIcon from "@heroicons/react/24/outline/HandThumbUpIcon";
import PageHeader from "../../components/PageHeader/PageHeader";

export default function Modules({ modules }) {
  let queryClient = useQueryClient();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  const isCourseComplete = () => {
    return modules.filter((module) => module.status === "COMPLETE").length ===
      modules.length
      ? true
      : false;
  };

  const getUiStatus = (enumStatus, updatedAt) => {
    let completed_on = new Date(updatedAt).toLocaleDateString(
      "en-GB"
    )
    switch (enumStatus) {
      case "NEW":
        return "Outstanding";
      case "INPROGRESS":
        return "In Progress";
      case "COMPLETE":
        return "Completed On: " + completed_on;
      case "FAILED":
        return "Failed";
      default:
        return "Unknown";
    }
  };

  const goToModule = (module) => {
    navigate("/module/" + module.module.id);
  };

  const retakeModule = (module) => {
    resetModuleMutation.mutate({
      userModuleId: module.id,
      moduleId: module.module.id,
    });
  };

  const resetModuleMutation = useMutation(
    ({ userModuleId, moduleId }) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/user-modules/reset`,
        {
          id: userModuleId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["user"]);
        navigate("/module/" + variables.moduleId);
      },
    }
  );

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title="My Course" />
        <div className="mt-4">
          <div className="sm:hidden">
            <select
              id="current-tab"
              name="current-tab"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={(e) => setSelectedTab(e.target.value)}
            >
              <option value={0}>Outstanding Modules</option>
              <option value={1}>Completed Modules</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              <a
                href="#"
                onClick={() => setSelectedTab(0)}
                className={classNames(
                  selectedTab == 0
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  "text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current="page"
              >
                Oustanding Modules
              </a>
              <a
                href="#"
                onClick={() => setSelectedTab(1)}
                className={classNames(
                  selectedTab == 1
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  " text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                Completed Modules
              </a>
            </nav>
          </div>
          <div className="my-5">
            <ul role="list" className="space-y-3">
              {modules &&
                modules
                  .filter(
                    (mod) =>
                      (selectedTab == 0 &&
                        (mod.status == "NEW" ||
                          mod.status == "INPROGRESS" ||
                          mod.status == "FAILED")) ||
                      (selectedTab == 1 && mod.status == "COMPLETE")
                  )
                  .map((module) => (
                    <li
                      key={module.module.id}
                      className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
                    >
                      <div className="flex justify-between text-gray-800">
                        <dt>
                          <p className="truncate text-gray-800 mr-3">
                            {module.module.ModuleName}
                          </p>
                        </dt>
                        <dd className="flex justify-between">
                          <span
                            className={classNames(
                              module.status == "NEW"
                                ? "bg-gray-100 text-gray-800"
                                : module.status == "FAILED"
                                ? "bg-red-100 text-red-800"
                                : module.status == "COMPLETE"
                                ? "bg-green-100 text-green-800"
                                : "bg-yellow-100 text-yellow-800",
                              "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium "
                            )}
                          >
                            {module.status != "COMPLETE" && (
                              <svg
                                className="mr-1.5 h-2 w-2 text-fathom-blue"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx={4} cy={4} r={3} />
                              </svg>
                            )}
                            {getUiStatus(module.status, module.updatedAt)}
                          </span>
                          {module.status != "COMPLETE" && (
                            <button
                              onClick={() =>
                                module.status == "FAILED"
                                  ? retakeModule(module)
                                  : goToModule(module)
                              }
                              type="button"
                              className="inline-flex items-center px-2.5 py-1.5 ml-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue"
                            >
                              {(() => {
                                switch (module.status) {
                                  case "NEW":
                                    return "Open Module";
                                  case "INPROGRESS":
                                    return "Continue Module";
                                  case "FAILED":
                                    return "Retake Module";
                                }
                              })()}
                            </button>
                          )}
                        </dd>
                      </div>
                      <div className="flex items-center">
                        {module.status != "COMPLETE" && (
                          <p className="text-sm text-gray-400">
                            <ClockIcon
                              className="flex-shrink-0 inline-block mr-1.5 h-5 w-5"
                              aria-hidden="true"
                            />{" "}
                            Approximately {module.module.moduleTimeMinutes}{" "}
                            minutes
                          </p>
                        )}
                      </div>
                    </li>
                  ))}

              {/* No Modules in list */}
              {selectedTab == 0 &&
                (!modules ||
                  (modules &&
                    modules.filter(
                      (mod) =>
                        mod.status == "NEW" ||
                        mod.status == "INPROGRESS" ||
                        mod.status == "FAILED"
                    ).length == 0)) && (
                  <span className="text-center text-sm text-gray-400 p-5">
                    {isCourseComplete && (
                      <div>
                        <div className="flex items-center justify-center">
                          <HandThumbUpIcon className="w-10 pb-2 text-fathom-blue" />
                        </div>
                        <p>
                          - You have completed all modules in this course, go to
                          My Certifications to download your certification -
                        </p>
                        <button
                          onClick={() => navigate("/certifications")}
                          type="button"
                          className="inline-flex mt-3 items-center px-2.5 py-1.5 ml-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue"
                        >
                          My Certifications
                        </button>
                      </div>
                    )}
                    {!isCourseComplete && (
                      <span> - You have no assigned modules - </span>
                    )}
                  </span>
                )}
              {selectedTab == 1 &&
                (!modules ||
                  (modules &&
                    modules.filter((mod) => mod.status == "COMPLETE").length ==
                      0)) && (
                  <p className="text-center text-sm text-gray-400 p-5">
                    {" "}
                    - You have not yet completed any modules -
                  </p>
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
