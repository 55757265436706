import React, { useState, useContext, useEffect } from "react";
import { classNames } from "../../services/utils";
import AddUserModal from "./AddUserModal";
import DeleteInviteModal from "./DeleteInviteModal";
import DisableUserModal from "./DisableUserModal";
import UserStatusModal from "./UserStatusModal";
import UserEditModal from "./UserEditModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PageHeader from "../../components/PageHeader/PageHeader";
import UsersTable from "./UsersTable";
import { CompanyContext } from "../../context/CompanyContext";
import { getUsers } from "../../services/strapiService";
import ConfirmationToast from "../../components/ConfirmationToast/ConfirmationToast";
import EmailReminderModal from "./EmailReminderModal";

export default function Users({ userLoggedIn, companyType }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const companyContext = useContext(CompanyContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [userProgress, setUserProgress] = useState([]);
  const [vessels, setVessels] = useState([]);

  const apiWebRoot = process.env.REACT_APP_API_ROOT;

  const { status, data } = useQuery(["vessels", companyContext?.id], () => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    if (token) {
      return axios.get(
        `${process.env.REACT_APP_API_ROOT}/company/${companyContext.id}/vessel`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).catch((error) => {
        console.log('Me Fetch Error')
        console.log(error.response.status);
      });
    } else return null;
  }, {
    enabled: !!companyContext
  });

  useEffect(() => {
    if (status == "success" && data && data.data) {
      if (data.data ) setVessels(data.data);
    }
  }, [status, data]);

  const gotoImportUsers = () => {
    navigate("/importusers");
  };

  const users = useQuery(["users", companyContext?.id], () => getUsers(companyContext?.id));

  const ranks = useQuery(["ranks"], () => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    return axios.get(`${process.env.REACT_APP_API_ROOT}/ranks`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  // Mutations
  const deleteMutation = useMutation(
    (id) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/deleteinvite`,
        {
          inviteId: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  // Mutations
  const disableMutation = useMutation(
    (id) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/disableuser`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showDeleteInviteModal, setShowDeleteInviteModal] = useState(false);
  const [showDisableUserModal, setShowDisableUserModal] = useState(false);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [showSendReminderEmailModal, setShowSendReminderEmailModal] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteInviteId, setDeleteInviteId] = useState(null);
  const [disableUserId, setDisableUserId] = useState(null);
  const [editUser, setEditUser] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastCode, setToastCode] = useState(0);

  const handleInviteSuccess = (data) => {
    let toastCode = 1;
    if (
      data.data.type === "permissionsError" ||
      data.data.type === "userExists"
    ) {
      toastCode = -1;
    }
    if (data.data.type === "addExistingUserToVessel") {
      setSelectedTab(0);
    }
    setToastMessage(data.data.message);
    setToastCode(toastCode);
    setShowToast(true);
  };

  const showAddUser = () => {
    setShowAddUserModal(true);
    setSelectedTab(1);
  };
  const showDeleteInvite = (id) => {
    setShowDeleteInviteModal(true);
    setDeleteInviteId(id);
  };
  const showDisableUser = (id) => {
    setShowDisableUserModal(true);
    setDisableUserId(id);
  };
  const showUserStatus = (user) => {
    setUserProgress(user);
    setShowUserStatusModal(true);
  };
  const showUserEdit = (user) => {
    setEditUser(user);
    setShowUserEditModal(true);
  };
  const deleteInvite = (id) => {
    deleteMutation.mutate(id);
    setDeleteInviteId(null);
    setShowDeleteInviteModal(false);
  };
  const disableUser = (id) => {
    disableMutation.mutate(id);
    setDisableUserId(null);
    setShowDisableUserModal(false);
  };
  const setCertificate = (certificateId) => {
    setReminder(certificateId);
    setShowSendReminderEmailModal(true);
  }
  const sendReminderEmail = async (certificateId) => {
    setReminder(certificateId);
    let localUser = JSON.parse(localStorage.getItem("user"));
    let token = localUser.jwt;
    try {
      const response = await axios.post(`${apiWebRoot}/invites/sendreminderemail`,
        { certificateId },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const message = response.data.message || 'Reminder email sent successfully!';
      setToastCode(1);
      setToastMessage(message);
    } catch (error) {
      console.error('Error:', error);
      setToastMessage('Failed to send reminder email.');
      setToastCode(-1);
    } finally {
      setShowSendReminderEmailModal(false);
      setShowToast(true);
    }
  }

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <div className="sm:flex sm:justify-between sm:items-baseline ">
          <PageHeader title="My Users" />
          <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
            <button
              onClick={showAddUser}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              Invite User
            </button>
            <button
              onClick={gotoImportUsers}
              className="inline-flex items-center ml-3 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              Bulk Import Users
            </button>
          </div>
        </div>

        <div className="mt-4">
          <div className="sm:hidden">
            <select
              id="current-tab"
              name="current-tab"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={(e) => setSelectedTab(e.target.value)}
            >
              <option value={0}>Active Users</option>
              <option value={1}>Invited Users</option>
              <option value={2}>Disabled Users</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              <a
                href="#"
                onClick={() => setSelectedTab(0)}
                className={classNames(
                  selectedTab == 0
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  "text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current="page"
              >
                Active Users
              </a>
              <a
                href="#"
                onClick={() => setSelectedTab(1)}
                className={classNames(
                  selectedTab == 1
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  "text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current="page"
              >
                Invited Users
              </a>
              <a
                href="#"
                onClick={() => setSelectedTab(2)}
                className={classNames(
                  selectedTab == 2
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  " text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                Disabled Users
              </a>
            </nav>
          </div>
          <div className="mt-4 overflow-hidden">
            <UsersTable
              data={users?.data?.data?.users.filter((user) => {
                return (
                  (selectedTab === 0 &&
                    user.accepted === true &&
                    (user.disabled === false || user.disabled === undefined)) ||
                  (selectedTab === 1 &&
                    (user.accepted === false ||
                      user.accepted === undefined ||
                      user.accepted === null)) ||
                  (selectedTab === 2 &&
                    user.disabled === true &&
                    user.accepted === true)
                );
              })}
              selectedTab={selectedTab}
              showUserEdit={showUserEdit}
              showDisableUser={showDisableUser}
              showDeleteInvite={showDeleteInvite}
              showUserStatus={showUserStatus}
              companyType={companyType}
              setShowSendReminderEmailModal={setShowSendReminderEmailModal}
              setCertificate={setCertificate}
            />
          </div>
        </div>
      </div>
      <AddUserModal
        showModal={showAddUserModal}
        setShowModal={setShowAddUserModal}
        onInviteSuccess={handleInviteSuccess}
        companyType={companyType}
        ranks={ranks?.data?.data?.data}
        vessels={vessels}
        userLoggedIn={userLoggedIn}
      />
      <DeleteInviteModal
        showModal={showDeleteInviteModal}
        setShowModal={setShowDeleteInviteModal}
        deleteInvite={deleteInvite}
        inviteId={deleteInviteId}
      />
      <DisableUserModal
        showModal={showDisableUserModal}
        setShowModal={setShowDisableUserModal}
        disableUser={disableUser}
        userId={disableUserId}
      />
      <UserStatusModal
        showModal={showUserStatusModal}
        setShowModal={setShowUserStatusModal}
        userProgress={userProgress}
      />
      <UserEditModal
        showModal={showUserEditModal}
        setShowModal={setShowUserEditModal}
        editUser={editUser}
        ranks={ranks?.data?.data?.data}
        vessels={vessels}
        companyType={companyType}
      />
      {showToast && (
        <ConfirmationToast
          message={toastMessage}
          setShowToast={setShowToast}
          toastCode={toastCode}
        />
      )}
      <EmailReminderModal
        showModal={showSendReminderEmailModal}
        setShowModal={setShowSendReminderEmailModal}
        sendReminderEmail={sendReminderEmail}
        reminder={reminder}
      />
    </>
  );
}
